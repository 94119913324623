import React, { useState } from 'react'
import axios from 'axios'
import { Input } from "./ui/input"
import { Button } from "./ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"
import { Loader2, FileDown } from 'lucide-react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordian"
import { Document, Page, Text, View, StyleSheet,BlobProvider,Image } from '@react-pdf/renderer'
import { getImage } from './ui/firebase'
interface HealthSurvey {
  submissionDate: {
    seconds: number;
    nanoseconds: number;
  };
  // Add other survey fields as needed
}

interface Employee {
  EmployeeName: string;
  EmployeeCode: string;
  Department: string;
  Designation: string;
  contactNumber: string;
  dateOfBirth: string;
  bloodGroup: string;
  NIC: string;

  healthSurveys: HealthSurvey[];
}





// Create styles for PDF
const styles = StyleSheet.create({
  page: { padding: 30, fontSize: 12, fontFamily: 'Helvetica' },
  title: { fontSize: 18, marginBottom: 10, fontWeight: 'bold' },
  subtitle: { fontSize: 14, marginBottom: 10, fontWeight: 'bold' },
  section: { marginBottom: 10 },
  row: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#000' },
  cell: { flex: 1, padding: 5 },
  headerCell: { flex: 1, padding: 5, backgroundColor: '#f0f0f0', fontWeight: 'bold' },
});


const formatDate = (dateObj: any) => {
  if (dateObj && typeof dateObj === 'object' && '_seconds' in dateObj) {
    // Convert Firestore Timestamp to JavaScript Date
    const milliseconds = dateObj._seconds * 1000 + Math.floor(dateObj._nanoseconds / 1000000);
    return new Date(milliseconds).toLocaleString();
  }
  return 'Invalid Date';
};

export default function EmployeeSearchComponent() {
  const [EmployeeCode, setEmployeeCode] = useState('')
  const [employee, setEmployee] = useState<Employee | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const handleSearch = async () => {
    if (EmployeeCode.length !== 10) {
      setError('Employee Code must be 10 characters long')
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_FETCH_EMPLOYEE_BY_ID}?docId=${EmployeeCode}`)
      if (response.data && response.data.healthSurveys!=null) {
        console.log("Employee data:", response.data)
        setEmployee(response.data)
      } else {
        setError('No employee found with this Employee Code')
        setEmployee(null)
      }
    } catch (error) {
      console.error('Error fetching employee data:', error)
      setError('An error occurred while fetching employee data')
      setEmployee(null)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };


    // Retrieve the image URL from Firebase Storage
    if(EmployeeCode)
    (async () => {
      try {
        const url = await getImage(EmployeeCode + '.jpeg');
        setImageUrl(url);
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    })();
    
      

      // Create PDF document
      console.log("iam searchpage",imageUrl)


const renderEmployeeData = (employee: Employee) => {
  return (
    <div className="space-y-4">
      <Table>
      <TableHeader>
        <TableRow>
        <TableHead>Field</TableHead>
        <TableHead>Value</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.entries(employee).map(([key, value]) => {
        if (key !== 'healthSurveys') {
          return (
          <TableRow key={key}>
            <TableCell className="font-medium">{key}</TableCell>
            <TableCell>{String(value)}</TableCell>
          </TableRow>
          );
        }
        return null;
        })}
      </TableBody>
      </Table>

      <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="health-surveys">
        <AccordionTrigger>Health Surveys ({employee.healthSurveys.length})</AccordionTrigger>
        <AccordionContent>
        {employee.healthSurveys.map((survey, index) => (
          <Accordion key={index} type="single" collapsible className="w-full mb-4">
          <AccordionItem value={`survey-${index}`}>
            <AccordionTrigger>
            Survey {index + 1} - {formatDate(survey.submissionDate)}
            </AccordionTrigger>
            <AccordionContent>
            <Table>
              <TableBody>
              {Object.entries(survey).map(([key, value]) => {
                if (key === 'submissionDate') {
                return (
                  <TableRow key={key}>
                  <TableCell className="font-medium">{key}</TableCell>
                  <TableCell>{formatDate(value)}</TableCell>
                  </TableRow>
                );
                } else if (typeof value === 'object' && value !== null) {
                return Object.entries(value).map(([subKey, subValue]) => (
                  <TableRow key={`${key}-${subKey}`}>
                  <TableCell className="font-medium">{`${key} - ${subKey}`}</TableCell>
                  <TableCell>{String(subValue)}</TableCell>
                  </TableRow>
                ));
                } else {
                return (
                  <TableRow key={key}>
                  <TableCell className="font-medium">{key}</TableCell>
                  <TableCell>{String(value)}</TableCell>
                  </TableRow>
                );
                }
              })}
              </TableBody>
            </Table>
            </AccordionContent>
          </AccordionItem>
          </Accordion>
        ))}
        </AccordionContent>
      </AccordionItem>
      </Accordion>
      
      <Button 
      onClick={() => {
        const htmlContent = `
        <html>
          <head>
          <title>${employee.EmployeeName}'s Health Information</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            h1, h2, h3 { color: #333; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }
            th { background-color: #f0f0f0; }
          </style>
          </head>
          <body>
          <h1>${employee.EmployeeName}'s Health Information</h1>
          ${imageUrl ? `<img src="${imageUrl}" alt="Employee" style="width: 100px; height: 100px; margin-bottom: 20px;" />` : '<h2>No image available</h2>'}
          <h2>Personal Information</h2>
          <table>
            <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            ${Object.entries(employee).map(([key, value]) => {
              if (key !== 'healthSurveys') {
              return `
                <tr>
                <td>${key}</td>
                <td>${String(value)}</td>
                </tr>
              `;
              }
              return '';
            }).join('')}
            </tbody>
          </table>
          <h2>Health Surveys</h2>
          ${employee.healthSurveys.map((survey, index) => `
            <div>
            <h3>Survey ${index + 1} - ${formatDate(survey.submissionDate)}</h3>
            <table>
              <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
              </thead>
              <tbody>
              ${Object.entries(survey).map(([key, value]) => {
                if (key === 'submissionDate') {
                return `
                  <tr>
                  <td>${key}</td>
                  <td>${formatDate(value)}</td>
                  </tr>
                `;
                } else if (typeof value === 'object' && value !== null) {
                return Object.entries(value).map(([subKey, subValue]) => `
                  <tr>
                  <td>${key} - ${subKey}</td>
                  <td>${String(subValue)}</td>
                  </tr>
                `).join('');
                } else {
                return `
                  <tr>
                  <td>${key}</td>
                  <td>${String(value)}</td>
                  </tr>
                `;
                }
              }).join('')}
              </tbody>
            </table>
            </div>
          `).join('')}
          </body>
        </html>
        `;
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${employee.EmployeeName}_health_information.html`;
        link.click();
      }}
      >
      Download HTML of All Surveys
      <FileDown className="ml-2 h-4 w-4" />
      </Button>
    </div>
  );
};

  return (
    <Card className="w-full max-w-4xl mx-auto  dark:bg-black ">
      <CardHeader>
        <CardTitle className="text-2xl">Employee Search</CardTitle>
        <CardDescription>
          Search for an employee using their Employee number.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex space-x-2 mb-4">
          <Input
            placeholder="Enter Employee Code (10 digits)"
            value={EmployeeCode}
            onChange={(e) => setEmployeeCode(e.target.value)}
            onKeyDown={handleKeyDown}
            className="flex-grow"
          />
          <Button onClick={handleSearch} disabled={isLoading}>
            {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Search'}
          </Button>
        </div>

        {error && <p className="text-red-500 mb-4">{"Employee Not Found/Check your Internet Connection"}</p>}

        {employee && renderEmployeeData(employee)}
      </CardContent>
    </Card>
  );
}