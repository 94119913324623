import DashboardComponent from './components/dashboard';
import LoginPage from './components/ui/LoginPage';
import './styles/globals.css';
import { useState, useEffect } from 'react';
import { useToast } from './components/ui/use-toast';
import { Toaster } from './components/ui/toaster';
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { toast } = useToast();
  useEffect(() => {
    try {
      const loginStatus = localStorage.getItem('isLoggedIn');
      setIsLoggedIn(loginStatus === 'true');
    } catch (err) {
      console.error('Failed to access localStorage:', err);
      setIsLoggedIn(false);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    toast({ title: 'Login Successful' });
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    toast({ title: 'Logged Out' });
  };

  return (
    <>
      <Toaster />
      <div className="App">
        {!isLoggedIn && <LoginPage onLoginSuccess={handleLoginSuccess} />}
        {isLoggedIn && <DashboardComponent onLogout={handleLogout} />}
      </div>
    </>
  );
}

export default App;
