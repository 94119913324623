'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { motion } from 'framer-motion'
import { Loader2 } from 'lucide-react'
import { Button } from './button'
import { Input } from './input'
import { Label } from './label'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './card'
import { useToast } from './use-toast'
import logoSrc from '../../assets/nav-logo-white.svg'
import lifelineLogo from '../../assets/nav-logo-white.svg'

const loginSchema = z.object({
  email: z.string().email({ message: 'Please enter a valid email address' }),
  password: z.string().min(8, { message: 'Password must be at least 8 characters long' }),
})

type LoginFormData = z.infer<typeof loginSchema>

interface LoginPageProps {
  onLoginSuccess?: () => void;
}

const ParticlesBackground = () => {
  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(50)].map((_, i) => (
        <div
          key={i}
          className="absolute bg-white/10 rounded-full animate-float"
          style={{
            width: Math.random() * 3 + 'px',
            height: Math.random() * 3 + 'px',
            top: Math.random() * 100 + '%',
            left: Math.random() * 100 + '%',
            animationDuration: `${Math.random() * 10 + 10}s`,
            animationDelay: `-${Math.random() * 10}s`,
          }}
        />
      ))}
    </div>
  );
};

const styles = `
  @keyframes heartbeat {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }

  .heartbeat-line {
    background-image: url("data:image/svg+xml,%3Csvg width='400' height='40' viewBox='0 0 400 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20 L50 20 L70 20 L80 0 L90 40 L100 0 L110 40 L120 20 L200 20' stroke='%232DD4BF' fill='none' stroke-width='1.5'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    position: absolute;
    width: 200%;
    height: 40px;
    animation: heartbeat 20s linear infinite;
    opacity: 0.3;
  }
`;

export default function LoginPage({ onLoginSuccess }: LoginPageProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  })
  const { toast } = useToast()

  const onSubmit = async (data: LoginFormData) => {
    setIsLoading(true)
    try {
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 2000))
      
      // Check against environment variables
      const validEmail =`${process.env.REACT_APP_API_USER_EMAIL}`
      const validPassword = `${process.env.REACT_APP_API_USER_PASSWORD}`

      if (data.email === validEmail && data.password === validPassword) {
        toast({
          title: 'Login Successful',
          description: 'Welcome back to the Health Survey App!',
        })
        
        if (onLoginSuccess) {
          onLoginSuccess()
        }
      } else {
        throw new Error('Invalid credentials')
      }
    } catch (error) {
      console.log('Login failed:', error);
      toast({
        title: 'Login Failed',
        description: 'Invalid email or password.',
        variant: 'destructive',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <style>{styles}</style>
      <div className="min-h-screen relative overflow-hidden bg-slate-900">
        {/* Multiple heartbeat lines */}
        <div className="absolute inset-0">
          {[...Array(10)].map((_, i) => (
            <div
              key={i}
              className="heartbeat-line"
              style={{
                top: `${i * 10}%`,
                animationDelay: `-${i * 3}s`,
                opacity: 0.2 + Math.random() * 0.2
              }}
            />
          ))}
        </div>

        {/* Lighter gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-slate-900/30 to-slate-900/70" />

        {/* Content - Centered with consistent max-width */}
        <div className="relative z-10 min-h-screen flex flex-col items-center justify-center px-4">
          {/* Container for both logo and form with consistent width */}
            <div className="w-full max-w-md mx-auto">
            {/* Logo Section */}
            <div className="mb-8 flex items-center justify-center">
              <div className="flex items-center justify-center">
              <img 
                src={logoSrc}
                alt="BitSolVentures Logo" 
              />
              </div>

              {/* Separator */}
              <div className="mx-8 h-16 w-[2px] bg-white/20" />

              {/* Lifeline Side */}
              <div className="flex items-center">
              <div className="w-1 h-16 bg-[#2DD4BF] rounded-full mr-4" />
              <div className="flex flex-col">
                <span className="text-2xl font-bold text-white">Lifeline</span>
                <span className="text-2xl font-bold text-white">Medical &</span>
                <span className="text-2xl font-bold text-white">Healthcare</span>
                <span className="text-2xl font-bold text-white">Services</span>
              </div>
              </div>
            </div>

            {/* Form Card - Using same color palette */}
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card className="w-full bg-slate-900/80 border border-slate-800">
                <CardHeader className="space-y-1">
                  <CardTitle className="text-2xl font-bold text-center text-white">Health Survey App</CardTitle>
                  <CardDescription className="text-center text-slate-400">Enter your credentials to access your account</CardDescription>
                </CardHeader>
                <CardContent>
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="email" className="text-slate-200">Email</Label>
                      <Input
                        id="email"
                        type="email"
                        placeholder="m@example.com"
                        {...register('email')}
                        className="bg-slate-800/50 border-slate-700 text-white placeholder:text-slate-500"
                      />
                      {errors.email && (
                        <p className="text-sm text-red-400">{errors.email.message}</p>
                      )}
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="password" className="text-slate-200">Password</Label>
                      <Input
                        id="password"
                        type="password"
                        {...register('password')}
                        className="bg-slate-800/50 border-slate-700 text-white"
                      />
                      {errors.password && (
                        <p className="text-sm text-red-400">{errors.password.message}</p>
                      )}
                    </div>
                    <Button 
                      type="submit" 
                      className="w-full bg-[#2DD4BF] hover:bg-[#2DD4BF]/90 text-slate-900 font-semibold"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          Logging in...
                        </>
                      ) : (
                        'Log in'
                      )}
                    </Button>
                  </form>
                </CardContent>
                <CardFooter>
                
                </CardFooter>
              </Card>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}

export type { LoginPageProps }