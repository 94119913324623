import React, { useState } from 'react'
import { Button } from "./button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./card"
import { Fingerprint, CheckCircle, XCircle, Loader2 } from 'lucide-react'
import uploadFingerprint from './firebase';


// Your Firebase configuration
// Import the functions you need from the SDKs you need


interface FingerPrintAuthProps {
  onSuccess: () => Promise<void>;
  onCancel: () => void;
  EmployeeCode:string;
}

export function FingerPrintAuth({ onSuccess, onCancel,EmployeeCode }: FingerPrintAuthProps) {
  const [authStatus, setAuthStatus] = useState<'idle' | 'authenticating' | 'success' | 'failure'>('idle')
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);
  const handleAuthenticate = () => {
    setAuthStatus('authenticating')
    // Simulating authentication process
    setTimeout(() => {
    
      // Fetch request to the server
    fetch("https://localhost:8443/SGIFPCapture", {
  "headers": {
    "accept": "*/*",
    "accept-language": "en-PK,en-US;q=0.9,en;q=0.8",
    "content-type": "application/x-www-form-urlencoded",
    "sec-ch-ua": "\"Chromium\";v=\"130\", \"Google Chrome\";v=\"130\", \"Not?A_Brand\";v=\"99\"",
    "sec-ch-ua-mobile": "?1",
    "sec-ch-ua-platform": "\"Android\"",
    "sec-fetch-dest": "empty",
    "sec-fetch-mode": "cors",
    "sec-fetch-site": "cross-site"
  },
  "referrer": "https://webapipython.secugen.com/",
  "referrerPolicy": "strict-origin-when-cross-origin",
  "body": "timeout=10000&quality=50&licstr=&templateformat=ISO&imagewsqrate=0.75",
  "method": "POST",
  "mode": "cors",
  "credentials": "omit"
})
      .then(response => response.json())
      .then(data => {
        const url="data:image/bmp;base64," + data.BMPBase64
        console.log(data)


        // Check if the response contains expected values
        const isSuccess = data.ErrorCode ==0

      if (isSuccess) {
        // Create a data URL from the base64 string
          const imageDataUrl = `data:image/bmp;base64,${data.BMPBase64}`;
          setImageDataUrl(imageDataUrl); // Set the image data URL in state
          uploadFingerprint(imageDataUrl,EmployeeCode)
          setAuthStatus('success');
          setTimeout(() => {
            onSuccess && onSuccess();
          }, 1000);
       
        } else {
          setAuthStatus('failure');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setAuthStatus('failure');
      });
    }, 2000);
  }

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="flex items-center justify-center text-2xl">
          <Fingerprint className="mr-2 h-6 w-6" />
          Fingerprint Authentication
        </CardTitle>
        <CardDescription className="text-center">
          Please provide your fingerprint to sign the health survey
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col items-center space-y-4">
        {authStatus === 'idle' && (
          <Button onClick={handleAuthenticate} className="w-full">
            <Fingerprint className="mr-2 h-4 w-4" />
            Authenticate with Fingerprint
          </Button>
        )}
        {authStatus === 'authenticating' && (
          <div className="text-center">
            <Loader2 className="animate-spin mx-auto mb-2" size={48} />
            <p>Waiting for fingerprint...</p>
          </div>
        )}
        {authStatus === 'success' && imageDataUrl && (
          <div className="text-center">
            <img src={imageDataUrl} alt="Authenticated Fingerprint" className="mx-auto mb-2" />
            
            <CheckCircle className="mx-auto mb-2" size={48} />
            <p>Authentication successful!</p>
          </div>
        )}
        {authStatus === 'failure' && (
          <div className="text-center text-red-500">
            <XCircle className="mx-auto mb-2" size={48} />
            <p>Authentication failed. Please try again.</p>
            <Button onClick={handleAuthenticate} className="mt-2">
              Retry
            </Button>
          </div>
        )}
        <Button 
          onClick={() => onCancel && onCancel()}
          variant="outline" 
          className="mt-2"
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  )
}

export type { FingerPrintAuthProps }