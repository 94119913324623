import { ref, uploadBytes,getStorage,getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { useState } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDaXQltC_NqyE4qGWQzDBcf5IP44aSOSno",
  authDomain: "artistic-milliners.firebaseapp.com",
  projectId: "artistic-milliners",
  storageBucket: "artistic-milliners.firebasestorage.app",
  messagingSenderId: "611181710906",
  appId: "1:611181710906:web:f3f1fe3ea5b1939a44ef03",
  measurementId: "G-CR5R2D39WK"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
// const [storageRef,setstorageRef] = useState(false)
// const storageRef = ref(storage, 'images/fingerprint.jpg'); // Set desired path for your image

export default async function uploadFingerprint(imageDataUrl: string,EmployeeCode:string) {
    try {
        // Extract base64 data and convert to Blob
        const base64Data = imageDataUrl.split(',')[1];
        const blob = base64ToBlob(base64Data, 'image/jpeg');
        const storageRef = ref(storage, EmployeeCode + '.jpeg'); 
        // Define metadata
        const metadata = {
            contentType: 'image/jpeg',
        };

        // Upload Blob to Firebase Storage
        const snapshot = await uploadBytes(storageRef, blob, metadata);
        console.log('Image uploaded successfully:', snapshot.metadata.fullPath);

        // Return the full path of the uploaded image
        return snapshot.metadata.fullPath;
    } catch (error) {
        console.error('Upload failed:', error);
        throw error;
    }
}

// Function to retrieve image URL from Firebase Storage
export async function getImage(imagePath: string) {
    try {
        const imageRef = ref(storage,imagePath);  // Reference the image path
        const url = await getDownloadURL(imageRef);  // Get the download URL
        console.log('Image URL:', url);
        return url;  // Return the download URL
    } catch (error) {
        console.error('Error getting image URL:', error);
        throw error;
    }
}

// Helper function to convert base64 string to Blob
function base64ToBlob(base64Data: string, contentType: string): Blob {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}